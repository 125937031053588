import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import styles from './UserProfile.module.css';
// import LearningIconImage from '../../assets/Business/learningIcon.png'
import GroupsIcon from '@mui/icons-material/Groups';
import SchoolIcon from '@mui/icons-material/School';
import { Button, LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useParams } from 'react-router-dom';
import userImage from '../../../assets/Business/UserLogo.png';
// import UserCards from '../../components/UserCards/UserCards';
import ChangeUserState from './ChangeUserState';
import ForgetpasswordUser from './ForgetUserPassword';
// import { APIDev } from '../../config/constants';
import UserCards from '../UserCards/UserCards';
import { APIExperience } from '../../../config/constants';

function UserProfile() {
    const { id } = useParams();
    // console.log(id, "id");
    const [userData, setUserData] = useState('');
    const [userCourses, setUserCourses] = useState([]);
    // const [email, setEmail] = useState("");
    // const [Deadline, setDeadline] = useState([]);
    const getSession = () => JSON.parse(localStorage.getItem("user_stu") || "{}");


    const [value, setValue] = useState('All');
    const eyouthPublicClient = JSON.parse(
        localStorage.getItem("userPublic_stu") || {}

    );
    let CompanyName = 'Taibah';
    let UserToken = eyouthPublicClient.access_token;
    console.log(UserToken, "UserToken");

    function GetUserData() {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "csrftoken=et1nG8kNZYj34tFKywVWlDzKhJbDc5bN3tSwLdQ4uWLLXUz4AqaIAPf3hUbscV9Q; sessionid=1|1i48z0393k0o1z4952octm264t7ikurz|r1r1aJqIcSNN|IjkyN2QzOWU5YWEyMGJiMTE2YWVjMjIyZWQwNTEyMGIzY2UwZDM4NmQ2YTYzNmI5MTRjNTFkMTY2MGE4OWJjNTMi:1rRZqD:o4IA0mhyP14dlNM_yeAFV_-jblU");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${APIExperience}/api/user/v1/userslist?company=${CompanyName}&user_id=${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setUserData(result.results[0]);
            })
            .catch(error => console.log('error', error));
    }

    function GetUserCoursesAndPrograss() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `jwt ${eyouthPublicClient.access_token}`);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "csrftoken=DIPMdnj41Y6dp1e5ijjEBOFUCX3j6OBgLfmV1FHGvMaCwTq5uhfPfFm4n1DT0hLU; openedx-language-preference=en; sessionid=1|cb51hr4ofm2lc74khv1u61xidmykaibc|HZUhnRVA8ERU|IjgyMzFiZTlhZDA1OThkYTBjNjNiY2I5YWU1NzllNjUwOTRhNTM2N2EzNzU2MTBkNmU2MjkyNzEwM2I2NmE3M2Ui:1rRq0C:3rJDKpcV9VHrqs-SVbGI_AxtW3w");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        console.log(id, "id ");

        fetch(`${APIExperience}/api/enrollment/v1/enrollment?user=${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result, "res fro");
                setUserCourses(result);
                // console.log(result, "result");
            })
            .catch(error => console.log('error', error));

    }
    useEffect(() => {
        GetUserCoursesAndPrograss();
        GetUserData();
        // eslint-disable-next-line
    }, []);


    function handleClick(event) {
        event.preventDefault();
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const breadcrumbs = [

        <a className='text-muted' href='/admin-dashboard/users'>Users </a>
        ,
        <Link
            className={styles.breadcrumbsColor}
            underline="hover"
            key="2"
            to="/userProfile/"
            onClick={handleClick}
        >
            User Profile
        </Link>
    ];
    // console.log(id, "id ");
    useEffect(() => {
        fetch(`${APIExperience}/api/user/v1/accounts/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `bearer ${getSession()?.access_token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                // setEmail(data.email);
                console.log(data.email, "data[0].email");
            })
            .catch(error => {
                console.error('Error from account:', error);
            });

    }, [id]);

    // useEffect(() => {
    //     const requestOptions = {
    //         method: "GET",
    //         redirect: "follow"
    //     };
    //     fetch(`https://python.taibahlearning.com/taibah/deadlines/?username=${email}`, requestOptions)
    //         .then((response) => response.json())
    //         .then((result) => {
    //             setDeadline(result?.Deadlines);
    //             console.log(result?.Deadlines, "result?.Deadlines");

    //         })
    //         .catch((error) => console.error(error));
    // }, [email]);
    // const getDeadlineForCourse = (courseKey) => {
    //     const deadline = Deadline.find(item => item.coursekey === courseKey);
    //     return deadline ? deadline.deadline : null;
    // };


    function TabPanel({ value, index, children }) {
        return (
            <div hidden={value !== index}>
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    return (
        <div className='container-fluid'>
            <div className='d-flex justify-content-around'>
                <div className='col-8 container'>
                    <Stack spacing={2} className='my-3'>
                        <Breadcrumbs
                            separator={<NavigateNextIcon className='text-muted' fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Stack>
                    <div className='d-flex justify-content-around'>
                        <UserCards count={userData.group === "" ? "N/A" : userData.group} title="Group Name" Icon={<GroupsIcon style={{ fontSize: "40px" }} />} />
                        <UserCards count={userCourses.length} title="Course" Icon={<SchoolIcon style={{ fontSize: "40px" }} />} />
                    </div>

                    <div className='mt-5'>
                        <Box sx={{ width: '100%' }}>
                            <div className='d-flex align-items-center justify-content-between w-75'>
                                <p className={` p-0 m-0 ${styles.Enrolled_Courses_Title}`}>Details Of Enrolled Courses</p>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    aria-label="secondary tabs example"
                                    sx={{
                                        '& .Mui-selected': {
                                            background: "#183696",
                                            borderRadius: "10px",
                                            color: "white !important"
                                        },
                                        '& .MuiTabs-indicator ': {
                                            background: "transparent"
                                        }
                                    }}
                                >
                                    <Tab value="All" label="All" />
                                    <Tab value="Completed" label="Completed" />
                                    <Tab value="Inprogress" label="Inprogress" />
                                </Tabs>
                            </div>
                            <div>
                                <TabPanel value={value} index="All">
                                    {userCourses?.map((course, index) => {
                                        // const completionPercentage = course.course_completion ? course?.course_completion?.trim().replace('%', '') : '0';
                                        const completionPercentage = course.course_completion ? Math.floor(+course?.course_completion?.split(' ')[0]) : '0';
                                        // console.log(completionPercentage)

                                        return (
                                            <div key={index} className='d-flex flex-column'>
                                                <h6>{course?.course_details?.course_name}</h6>
                                                {/* {course.deadline && (
                                                    <small className='text-muted'>course Deadline: {course.deadline}</small>
                                                )} */}
                                                <div className='d-flex align-items-center justify-content-between '>
                                                    <LinearProgress
                                                        className="prograss-bar w-50 mb-1"
                                                        variant="determinate"
                                                        value={completionPercentage}
                                                        color='error'
                                                    />
                                                    <p style={{ marginTop: "6px" }}>{completionPercentage} % </p>
                                                    <div>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                background: "#183696",
                                                                "&:hover": { background: "#978c8cb7", color: "#183696" }
                                                            }}
                                                        >
                                                            <a className='text-white' href={`https://apps.learning.TaybaTr.com/learning/course/${course?.course_details?.course_id}/progress/${userData.id}/`}>View Progress</a>
                                                        </Button>

                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                </TabPanel>
                                <TabPanel value={value} index="Completed">
                                    {userCourses
                                        .filter(course => parseInt(course?.course_completion?.trim().replace('%', '')) === 100)
                                        .length === 0 ? (
                                        <div>No completed courses</div>
                                    ) : (
                                        userCourses
                                            .filter(course => parseInt(course?.course_completion?.trim().replace('%', '')) === 100)
                                            .map((completedCourse, index) => {
                                                const completionPercentage = completedCourse.course_completion ? completedCourse.course_completion.trim().replace('%', '') : '0';

                                                return (
                                                    <div key={index} className='d-flex flex-column'>
                                                        <h6>{completedCourse?.course_details?.course_name}</h6>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <LinearProgress
                                                                className="prograss-bar w-50 mb-1"
                                                                variant="determinate"
                                                                value={parseInt(completionPercentage)}
                                                                color='error'
                                                            />
                                                            <p style={{ marginTop: "6px" }}>{parseInt(completionPercentage)} % </p>
                                                            <div>
                                                                <Button
                                                                    variant="contained"
                                                                    sx={{
                                                                        background: "#183696",
                                                                        "&:hover": { background: "#978c8cb7", color: "#183696" }
                                                                    }}
                                                                >
                                                                    <a className='text-white' href={`https://apps.learning.TaybaTr.com/learning/course/${completedCourse?.course_details?.course_id}/progress/${userData.id}/`}>View Progress</a>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                    )}
                                </TabPanel>
                                <TabPanel value={value} index="Inprogress">
                                    {userCourses
                                        .filter(course => parseInt(course?.course_completion?.trim().replace('%', '')) !== 100)
                                        .length === 0 ? (
                                        <div>No in-progress courses</div>
                                    ) : (
                                        userCourses
                                            .filter(course => parseInt(course?.course_completion?.trim().replace('%', '')) !== 100)
                                            .map((inProgressCourse, index) => {
                                                const completionPercentage = inProgressCourse.course_completion ? inProgressCourse?.course_completion?.trim().replace('%', '') : '0';
                                                // console.log(inProgressCourse, "inProgressCourse");
                                                return (
                                                    <div key={index} className='d-flex flex-column'>
                                                        <h6>{inProgressCourse?.course_details?.course_name}</h6>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <LinearProgress
                                                                className="prograss-bar w-50 mb-1"
                                                                variant="determinate"
                                                                value={parseInt(completionPercentage)}
                                                                color='error'
                                                            />
                                                            <p style={{ marginTop: "6px" }}>{parseInt(completionPercentage)} % </p>
                                                            <div>
                                                                <Button
                                                                    variant="contained"
                                                                    sx={{
                                                                        background: "#183696",
                                                                        "&:hover": { background: "#978c8cb7", color: "#183696" }
                                                                    }}
                                                                >
                                                                    <a className='text-white' href={`https://apps.learning.TaybaTr.com/learning/course/${inProgressCourse?.course_details?.course_id}/progress/${userData.id}/`}>View Progress</a>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                    )}
                                </TabPanel>
                            </div>
                        </Box>
                    </div>
                </div>
                <div className='col-4' style={{ background: "#F3F3F3" }}>
                    <div className='d-flex flex-column align-items-center justify-content-center my-4'>
                        <h6>{userData.name}</h6>
                        <img className="rounded-circle w-25 my-3" src={userImage} alt="username" />
                        <h6 className='mt-3'>{userData.username}</h6>
                    </div>
                    <div className='container'>
                        <h4>personal data</h4>
                        <div className={styles.user_personal_data}>
                            <div className='d-flex justify-content-between border-bottom p-2'>
                                <p>phone number </p>
                                <p>{userData.phone_number === null ? "not found" : userData.phone_number} </p>
                            </div>
                            <div className='d-flex justify-content-between border-bottom p-2'>
                                <p>Email Address </p>
                                <p>{userData.email} </p>
                            </div>
                            <div className='d-flex justify-content-between border-bottom p-2'>
                                <p>User State </p>
                                <p>{userData.is_active ? "Active" : "Inactive "} </p>
                            </div>
                            <div className='d-flex justify-content-center border-bottom p-2'>
                                <p><ForgetpasswordUser token={UserToken} username={userData.username} /></p>
                            </div>
                            <div className='d-flex justify-content-center p-2'>
                                <p><ChangeUserState /> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

}
export default UserProfile;
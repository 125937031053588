import React from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import line from '../../assets/Tiba/mostUnderline.svg';
import styles from "./Partners.module.css";
import { PartnersList } from "./partnersList";


function Partners() {
    const { t } = useTranslation();

    return (
        <>
            <div className='d-flex flex-column justify-around-center align-items-center my-3'>
                <h2 className={styles.title} >{t("Partners")}</h2>
                <LazyLoadImage src={line} alt="underline" className={`${styles.lineWeb} ${styles.lineMobile} `} />
            </div>
            <div id={styles.partnersContainer}>

                {PartnersList.map((item, index) => (
                    <div className={styles.imgContainer}>
                        <LazyLoadImage className={styles.partnerImg}
                            effect='blur'
                            key={index}
                            src={require('../../assets/New_Partners/' + item)}
                            alt={`partner no. ${index + 1}`}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export default Partners
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/TebaImages/logo-taibah 1.png';
import company from '../../assets/pop_up_Icons/foundation_torso-business_(1).webp';
import trainee from '../../assets/pop_up_Icons/la_chalkboard-teacher_(2).webp';
import trainer from '../../assets/pop_up_Icons/ph_student.webp';
import styles from './PopUpJoinUs.module.css';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function PopUpJoinUs(props) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div style={{ fontFamily: "Tajawal" }}>
            <button aria-label="button to be clicked"
                aria-labelledby="button to be clicked" onClick={handleClickOpen}
                className={props?.styles}
            >{props?.title}</button>

            <Dialog
                style={{ fontFamily: "Tajawal !important" }}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <CloseIcon className={`${styles.closeIcon} m-3`} onClick={handleClose} />
                <DialogTitle className='d-flex justify-content-center'>

                    <LazyLoadImage src={logo} alt="logo" width="35%" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className='d-flex justify-content-center text-dark bold ' style={{ fontSize: "26.57px", fontFamily: "Tajawal" }} id="alert-dialog-slide-description">
                        {t("How would you like to join us?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ fontFamily: "Tajawal" }} className='d-flex flex-column align-items-around'>
                    <Link to='/signup' style={{ fontFamily: "Tajawal" }} className='mb-3'>
                        <div className='btn btn-light' style={{ fontSize: "23.25px", fontFamily: "Tajawal" }}>
                            <LazyLoadImage src={trainer} alt='trainer-icon' className='mx-2' width="35px" />
                            {t("Join us as a participant")}
                        </div>
                    </Link>
                    <Link to="/studio" className='mb-3'>
                        <div className='btn btn-light' style={{ fontSize: "23.25px", fontFamily: "Tajawal" }}>
                            <LazyLoadImage src={trainee} alt='trainer-icon' className='mx-2' width="35px" />
                            {t("Join us as an Instructor")}
                        </div>
                    </Link>
                    {/* <a href="" rel="noreferrer" target='_blank' className='mb-3'>
                        <div className='btn btn-light px-4' style={{ fontSize: "23.25px", fontFamily: "Tajawal" }}>
                            <LazyLoadImage src={company} alt='trainer-icon' className='mx-2' width="35px" />
                            {t("Join us as a company")}
                        </div>
                    </a> */}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PopUpJoinUs;
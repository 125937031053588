import GroupAddIcon from '@mui/icons-material/GroupAdd';
import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import courseEnroll from "../../../assets/Business/courseEnrollLogo.svg";
import groupsLogo from "../../../assets/Business/groupsLogo.svg";
import lmsTrackLogo from "../../../assets/Business/lmstrackLogo.svg";
import usersLogo from "../../../assets/Business/usersLogo.svg";
import styles from "./LayoutDashboard.module.css";
export default function LayoutDashboard() {
    // const user = JSON.parse(localStorage.getItem("user_stu"));
    let primarycolor = "#294595";

    const navigate = useNavigate();

    const checkUser = () => {
        if (!localStorage.getItem("user_stu")) window.location.assign("/signin");
        else {
            let { username } = JSON.parse(localStorage.getItem("user_stu"));
            if (!["Taibah", "admin"].includes(username)) navigate("/");
        }
    };
    useEffect(() => {
        checkUser();
    }, []);


    return (
        <>
            <div className={styles.dashboardContainer}>
                <div className={styles.tabsContainer}>
                    <ul className={styles.tabsNavigation}>
                        <NavLink
                            to="users"
                            className={({ isActive }) =>
                                `${styles.navigateContainer} ${isActive ? styles.active : styles.notActive
                                }`
                            }
                        >
                            <div className={styles.navItem}>
                                <img src={usersLogo} alt="usersLogo" />
                                <p style={{ color: primarycolor }}>Users</p>
                            </div>
                        </NavLink>
                        <NavLink
                            to="groups"
                            className={({ isActive }) =>
                                `${styles.navigateContainer} ${isActive ? styles.active : styles.notActive
                                }`
                            }
                        >
                            <div className={styles.navItem}>
                                <img src={groupsLogo} alt="groupsLogo" />
                                <p style={{ color: primarycolor }}>Groups</p>
                            </div>
                        </NavLink>
                        <NavLink
                            to="lms-track"
                            className={({ isActive }) =>
                                `${styles.navigateContainer} ${isActive ? styles.active : styles.notActive
                                }`
                            }
                        >
                            <div className={styles.navItem}>
                                <img src={lmsTrackLogo} alt="lmsTrackLogo" />
                                <p style={{ color: primarycolor }}>LMS Tracking</p>
                            </div>
                        </NavLink>
                        <NavLink
                            to="course-enrollment"
                            className={({ isActive }) =>
                                `${styles.navigateContainer} ${isActive ? styles.active : styles.notActive
                                }`
                            }
                        >
                            <div className={styles.navItem}>
                                <img src={courseEnroll} alt="lmsTrackLogo" />
                                <p style={{ color: primarycolor }}>Course Enrollment</p>
                            </div>
                        </NavLink>
                        {/* <NavLink
                                to="craete-learning-path"
                                className={({ isActive }) =>
                                    `${styles.navigateContainer} ${isActive ? styles.active : styles.notActive
                                    }`
                                }
                            >
                                <div className={styles.navItem}>
                                    <CastForEducationIcon/>
                                    <p style={{ color: primarycolor }}>Craete Learning Path</p>
                                </div>
                            </NavLink> */}

                        {/* <NavLink
                            to="notification"
                            className={({ isActive }) =>
                                `${styles.navigateContainer} ${isActive ? styles.active : styles.notActive
                                }`
                            }
                        >
                            <div className={styles.navItem}>
                                <img src={notificationLogo} alt="notificationLogo" />
                                <p style={{ color: primarycolor }}>Notification</p>
                            </div>
                        </NavLink> */}
                        <NavLink
                            to="register"
                            className={({ isActive }) =>
                                `${styles.navigateContainer} ${isActive ? styles.active : styles.notActive
                                }`
                            }
                        >
                            <div className={styles.navItem}>
                                <GroupAddIcon style={{
                                    color: "black",
                                    fontSize: "40px"
                                }} />
                                <p style={{ color: primarycolor }}>Register </p>
                            </div>
                        </NavLink>

                    </ul>
                </div>
                <div style={{ padding: " 20px 10px ", width: "98%" }}>
                    {" "}
                    <Outlet />
                </div>
            </div>

        </>
    );
}

export const PartnersList = [
  "BC.png",
  "المركز الوطنى لإدارة النفايات.png",
  "BMC.png",
  "CISCO.png",
  "citc_logo.svg",
  "esri-logo-globe-tm.png",
  "HU.jpeg",
  "IEEE.png",
  "logo.png",
  "logo (1).png",
  "LogoBig.png",
  "logo_dark.png",
  "NHS.jpeg",
  "RE1Mu3b.png",
  "TREND.png",
  "vmware-logo-grey.svg",
  "logo.svg",
]

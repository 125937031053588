import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as yup from "yup";
import { useFormik } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router-dom";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { APIExperience } from '../../../config/constants';

export default function ChangeUserState() {
    const { id } = useParams();
    //     localStorage.getItem("userPublic_stu") || {}
    // );
    let CompanyName = 'Taibah';
    // console.log(CompanyName, "CompanyName");
    // console.log(id , "id");

    const [open, setOpen] = useState(false);
    const [activationStatus, setActivationStatus] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFormSubmit = (values) => {
        const activatedValue = values.state === "Active" ? "True" : "False";

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // Include your other headers here
            },
            body: JSON.stringify({
                activated: activatedValue,
            }),
            redirect: "follow",
        };
        // console.log(CompanyName, "CompanyName before function");
        fetch(
            `${APIExperience}/api/user/v1/one_users_toggle_activate?company=${CompanyName}&user=${id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.data === "users deactivated successfully") {
                    setActivationStatus("deactivated");
                    window.location.reload();
                } else {
                    setActivationStatus("activated");
                    window.location.reload();
                }
                // console.log(result, "res");
            })
            .catch((error) => {
                console.log("error", error);
                // Handle error, if needed
            });
    };

    const formikValidation = useFormik({
        enableReinitialize: true,
        initialValues: { state: "Active" },
        validationSchema: yup.object({
            state: yup.string().required("State is required"),
        }),
        onSubmit: handleFormSubmit,
    });

    return (
        <>
            <span
                onClick={handleClickOpen}
                className="text-end mt-1"
                style={{ color: "#183696", textDecoration: "underline", cursor: "pointer" }}
            >
                Change User State
            </span>

            <Dialog open={open} onClose={handleClose}>
                <form id="submitForm" onSubmit={formikValidation.handleSubmit}>
                    <DialogTitle className="text-primary">
                        Change User State
                    </DialogTitle>
                    <DialogContent>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={formikValidation.values.state || undefined}
                                onChange={(e) => {
                                    formikValidation.setFieldValue("state", e.target.value);
                                    // console.log(e.target.value, "value")
                                }}
                                name="radio-buttons-group"
                            >
                                <FormControlLabel
                                    value="Active"
                                    control={<Radio />}
                                    label="Active"
                                />
                                <FormControlLabel
                                    value="Inactive"
                                    control={<Radio />}
                                    label="Inactive"
                                />
                            </RadioGroup>
                            {activationStatus === "deactivated" && (
                                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                                    Users deactivated successfully.
                                </Alert>
                            )}
                            {activationStatus === "activated" && (
                                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                                    User activated successfully.
                                </Alert>
                            )}
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                handleClose();
                            }}
                            className="btn btn-danger border"
                        >
                            Cancel
                        </button>
                        <button
                            form="submitForm"
                            type="submit"
                            className="btn btn-primary border"
                            style={{
                                background: "#183696",
                            }}
                        >
                            Change
                        </button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

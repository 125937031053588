import * as React from "react";
import loadingGif from "../../assets/Tiba/Tiba_Loading.gif";
import styles from "./LoadingBar.module.css";

export default function LoadingBar() {
    return (
        <div className={styles.loadingBar}>
            <img src={loadingGif} alt="loading..." />
        </div>
    );
}
